import React, { useState } from "react";

import { Contact as ContactImage } from "../assets/img/index";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [enquiry, setEnquiry] = useState("");

  return (
    <>
      <div class="snackbar py-3 px-3"></div>
      <div class="content container full-width overpass">
        <div class="row background-img header overpass primary-color-light">
          <hr class="border-line-header" />
          <h1 class="large px-md-0 px-3">contact fun for rebels</h1>
          <hr class="border-line-header" />
        </div>

        <div class="row primary-color justify-content-center">
          <div className="col-md-6">
            <img
              src={ContactImage}
              alt={`Don't read the next sentence! You're a little rebel, I like you.`}
            />
          </div>
          <div className="col-md-6 py-5">
            <div
              style={{ top: "25%" }}
              class="d-flex flex-column position-sticky jumbotron primary-color-light justify-content-center"
            >
              <h2 class="mx-auto py-3 text-center">Contact us with any queries below:</h2>
              <form method="post" name="contact">
                <input type="hidden" name="form-name" value="contact" />
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <ion-icon name="person-outline"></ion-icon>
                    </span>
                  </div>
                  <input
                    name="name"
                    type="text"
                    class="form-control"
                    placeholder="Full Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                  </div>
                  <input
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <ion-icon name="call-outline"></ion-icon>
                    </span>
                  </div>
                  <input
                    name="phone"
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div class="input-group pb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <ion-icon name="information-outline"></ion-icon>
                    </span>
                  </div>
                  <textarea
                    class="form-control"
                    name="info"
                    placeholder="Your query..."
                    value={enquiry}
                    onChange={(e) => setEnquiry(e.target.value)}
                  ></textarea>
                </div>
                <input type="submit" className="btn primary" value="Send" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
