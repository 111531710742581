import React from "react";
import { Link } from "react-router-dom";
import {
  Masonry0,
  Masonry1,
  Masonry2,
  Masonry3,
  Masonry4,
  Masonry5,
  Masonry6,
  Masonry7,
  Masonry8,
  Masonry9,
  Logo,
} from "../assets/img/index";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Home = () => {
  return (
    <div>
      <div className="home-grid-cont">
        <div className="grid home-grid">
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 600: 3, 1080: 4 }}>
            <Masonry>
              <div>
                <img src={Masonry1} alt="" />
              </div>
              <div>
                <img src={Masonry0} alt="" />
              </div>
              <div>
                <img src={Masonry2} alt="" />
              </div>
              <div>
                <img src={Masonry3} alt="" />
              </div>
              <div>
                <img src={Masonry4} alt="" />
              </div>
              <div>
                <img src={Masonry5} alt="" />
              </div>
              <div>
                <img src={Masonry6} alt="" />
              </div>
              <div>
                <img src={Masonry7} alt="" />
              </div>
              <div>
                <img src={Masonry8} alt="" />
              </div>
              <div>
                <img src={Masonry9} alt="" />
              </div>
            </Masonry>
          </ResponsiveMasonry>
        </div>
        <div className="home-logo">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div className="content container-fluid full-width overpass">
        <div className="row primary-color-light py-5 justify-content-center align-items-center">
          <i className="far fa-envelope fa-lg px-3"></i>
          <h2 className="medium">
            <Link to="/contact">Contact fun for rebels here.</Link>
          </h2>
        </div>

        <div className="row my-5 py-3 mx-auto secondary-color highlight-container overpass-bold smallmed">
          <div className="col-md-4 py-1 py-md-0 d-flex justify-content-center highlight">
            <img src={Masonry9} alt="alt" />
            <div className="highlight-text-cont d-flex justify-content-center align-items-center">
              <div className="highlight-text">
                <hr className="border-line" />
                | Surf <br />
                | Skate <br />
                | Beach <br />
                | Adventure
                <hr className="border-line" />
              </div>
            </div>
          </div>
          <div className="col-md-4 py-1 py-md-0 d-flex justify-content-center highlight">
            <img src={Masonry4} alt="alt" />
            <div className="highlight-text-cont d-flex justify-content-center align-items-center">
              <div className="highlight-text">
                <hr className="border-line" />
                Apparel for the rebels
                <hr className="border-line" />
              </div>
            </div>
          </div>
          <div className="col-md-4 py-1 py-md-0 d-flex justify-content-center highlight">
            <img src={Masonry8} alt="alt" />
            <div className="highlight-text-cont d-flex justify-content-center align-items-center">
              <div className="highlight-text">
                <hr className="border-line" />
                Not for the ordinary
                <hr className="border-line" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
