import React, { useEffect, useContext } from "react";
import { Routes as Router, Route } from "react-router-dom";

import Client from "shopify-buy";

import Home from "../screens/Home";
import Contact from "../screens/Contact";
import About from "../screens/About";
import Store from "../screens/Store";

import { ShopifyContext } from "../contexts/shopify";

const Routes = () => {
  // eslint-disable-next-line no-unused-vars
  const [__, dispatch] = useContext(ShopifyContext);

  const client = Client.buildClient({
    domain: `${process.env.REACT_APP_SHOPIFY_STORE}.myshopify.com`,
    storefrontAccessToken: process.env.REACT_APP_SHOPIFY_TOKEN,
  });

  useEffect(() => {
    client.product.fetchQuery();
    client.product.fetchAll().then((products) => {
      // Do something with the products
      //TODO - Filter products without ID
      dispatch({ type: "set_products", payload: products });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Router>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/store" element={<Store />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Router>
  );
};

export default Routes;
