export const Masonry0 = require("./masonry/image0.jpeg");
export const Masonry1 = require("./masonry/image1.jpeg");
export const Masonry2 = require("./masonry/image2.jpeg");
export const Masonry3 = require("./masonry/image3.jpeg");
export const Masonry4 = require("./masonry/image4.jpeg");
export const Masonry5 = require("./masonry/image5.jpeg");
export const Masonry6 = require("./masonry/image6.jpeg");
export const Masonry7 = require("./masonry/image7.jpeg");
export const Masonry8 = require("./masonry/image8.jpeg");
export const Masonry9 = require("./masonry/image9.jpeg");

export const Family = require("./general/family.jpg");
export const Logo = require("./general/logo.png");
export const LogoBanner = require("./general/logo-banner.png");
export const Fire = require("./general/fire.jpeg");
export const Beach = require("./general/beach.jpg");

export const Contact = require("./general/contact.jpg");
