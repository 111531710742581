import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { ShopifyContext } from "../contexts/shopify";

const Nav = () => {
  const [state] = useContext(ShopifyContext);
  const productTypes = [...new Set(state.products.map((product) => product.productType))].filter(
    (type) => type
  );

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <nav className="navbar py-3 navbar-expand-lg overpass">
      <button
        className="navbar-toggler collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded={!isNavCollapsed ? true : false}
        aria-label="Toggle navigation"
        onClick={handleNavCollapse}
      >
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </button>
      <div
        className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav mx-auto">
          <li className="nav-item mx-md-3 mx-0">
            <Link onClick={handleNavCollapse} className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item mx-md-3 mx-0">
            <Link onClick={handleNavCollapse} className="nav-link" to="/about">
              About
            </Link>
          </li>
          <li className="nav-item mx-md-3 mx-0">
            <Link onClick={handleNavCollapse} className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
          <li className="nav-item dropdown mx-md-3 mx-0">
            <button
              className="nav-link dropdown-toggle mx-auto"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Store
            </button>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              {productTypes.map((type) => (
                <Link
                  onClick={handleNavCollapse}
                  key={type}
                  className="dropdown-item"
                  to={`/store#${type}`}
                >
                  {type}
                </Link>
              ))}
              <Link
                onClick={handleNavCollapse}
                className="dropdown-item text-center"
                to={`/store#all`}
              >
                Show All
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
