import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
  "@context": "http://www.schema.org",
  "@type": "ClothingStore",
  "name": "Fun for Rebels",
  "url": "https://www.funforrebels.com",
  "logo": "https://funforrebels.com/logo.png",
  "priceRange": "$$",
  "address": {
     "@type": "PostalAddress",
     "streetAddress": "33 P S",
     "addressLocality": "North Berwick",
     "postalCode": "H39 5FP",
     "addressCountry": "United Kingdom"
  }
}`}
      </script>
    </Helmet>
  );
};

export default SEO;
