import React from "react";

import "./App.css";
import "./assets/css/main.css";
import { BrowserRouter } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import SEO from "./components/SEO";

import { ShopifyProvider } from "./contexts/shopify";

import Routes from "./router/Routes";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

function App() {
  return (
    <HelmetProvider>
      <ShopifyProvider>
        <SEO />
        <BrowserRouter>
          <Nav />
          <Routes />
          <Footer />
        </BrowserRouter>
      </ShopifyProvider>
    </HelmetProvider>
  );
}

export default App;
