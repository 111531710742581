import React, { useEffect, useContext } from "react";
import ShopifyBuy from "@shopify/buy-button-js";
import { useLocation } from "react-router-dom";

import { ShopifyContext } from "../contexts/shopify";
import shopifyOptions from "../constants/shopifyOptions";

const Store = () => {
  const [state] = useContext(ShopifyContext);
  const { products } = state;

  const { hash } = useLocation();
  const selectedProduct = decodeURIComponent(hash.replace("#", ""));

  const filteredProducts =
    selectedProduct === "all"
      ? products
      : products.filter((product) => product.productType === selectedProduct);

  const shopifyClient = ShopifyBuy.buildClient({
    domain: `${process.env.REACT_APP_SHOPIFY_STORE}.myshopify.com`,
    storefrontAccessToken: process.env.REACT_APP_SHOPIFY_TOKEN,
  });

  const ui = ShopifyBuy.UI.init(shopifyClient);

  const decodeURL = (str) => {
    if (str) {
      const decoded = Buffer.from(str, "base64").toString();
      return decoded ? decoded.split("/").pop() : null;
    }
  };

  useEffect(() => {
    filteredProducts.map((product, i) => {
      const id = decodeURL(product.id);
      ui.createComponent("product", {
        id: id,
        node: document.getElementById(`product-${i}`),
        options: shopifyOptions,
        moneyFormat: "£{{amount}}",
      });
      return 200;
    });
  }, [state, hash]);

  const Loader = () => {
    return (
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  return (
    <div>
      <div className="row background-img header overpass primary-color-light">
        <hr className="border-line-header" />
        <h1 className="large px-md-0 px-3">the store</h1>
        <h2>{selectedProduct === "all" ? null : selectedProduct}</h2>
        <hr className="border-line-header" />
      </div>
      {filteredProducts ? (
        <div className="container product-list">
          <div className="row mt-5">
            {filteredProducts.map((product, i) => (
              <div className="product col-md-4" id={`product-${i}`} key={product.id}>
                <div className="loader d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Store;
