const shopifyOptions = {
  product: {
    events: {
      beforeRender: function(component) {
        component.node.querySelector(".loader").setAttribute("style", "display:none !important");
      },
      afterRender: function(component) {
        var width = document.querySelector(".shopify-buy__product-img-wrapper").offsetWidth;
        document.querySelector(".shopify-buy__product__variant-img").style.height = width + "px";
      },
    },
    imageSize: "1024x1024",
    iframe: false,
    styles: {
      product: {
        "@media (min-width: 601px)": {
          "max-width": "calc(25% - 20px)",
          "margin-left": "20px",
          "margin-bottom": "50px",
        },
      },
      button: {
        "font-family": "Avant Garde, sans-serif",
        ":hover": {
          "background-color": "#4d4d4d",
        },
        "background-color": "#000000",
        ":focus": {
          "background-color": "#4d4d4d",
        },
      },
    },
    buttonDestination: "modal",
    contents: {
      options: false,
    },
    text: {
      button: "View product",
    },
  },
  productSet: {
    styles: {
      products: {
        "@media (min-width: 601px)": {
          "margin-left": "-20px",
        },
      },
    },
  },
  modalProduct: {
    contents: {
      img: false,
      imgWithCarousel: true,
      button: false,
      buttonWithQuantity: true,
    },
    styles: {
      product: {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px",
        },
      },
      button: {
        "font-family": "Avant Garde, sans-serif",
        ":hover": {
          "background-color": "#4d4d4d",
        },
        "background-color": "#000000",
        ":focus": {
          "background-color": "#4d4d4d",
        },
      },
    },
    text: {
      button: "Add to cart",
    },
  },
  cart: {
    styles: {
      button: {
        "font-family": "Avant Garde, sans-serif",
        ":hover": {
          "background-color": "#4d4d4d",
        },
        "background-color": "#000000",
        ":focus": {
          "background-color": "#4d4d4d",
        },
      },
    },
    text: {
      total: "Subtotal",
      button: "Checkout",
    },
    contents: {
      note: true,
    },
    popup: false,
  },
  toggle: {
    styles: {
      toggle: {
        "font-family": "Avant Garde, sans-serif",
        "background-color": "#000000",
        ":hover": {
          "background-color": "#4d4d4d",
        },
        ":focus": {
          "background-color": "#4d4d4d",
        },
      },
    },
  },
};

export default shopifyOptions;
