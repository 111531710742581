import React from "react";

const Footer = () => {
  return (
    <div className="primary-color auto-height overpass footer py-3" id="footer">
      <div className="row mx-auto">
        <div className="col-md-6 mx-auto d-flex align-items-center justify-content-center medium text-center">
          Fun For Rebels
        </div>
        <div className="col-md-6 mx-auto d-flex align-items-center justify-content-center text-center">
          <p className="xsmall d-flex align-items-center justify-content-start">
            <a href="https://elementsdevelopment.co.uk" rel="noopener noreferrer" target="_blank">
              Designed and developed by @elementsdevelopment
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
