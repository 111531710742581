import React from "react";

import { Beach, LogoBanner } from "../assets/img/index";

const About = () => {
  return (
    <>
      <div class="content container full-width overpass overflow-hidden">
        <div class="row background-img header overpass">
          <img className="w-100 about-img" alt="beach campfire" src={Beach} />
          <div className="header-overlay">
            <div className="home-logo">
              <img src={LogoBanner} alt="logo" />
            </div>
            <div className="d-flex flex-column">
              <h1 class="large px-md-0 px-3 secondary-color-text text-center">
                about fun for rebels
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="primary-color about-content">
          <div className="container">
            <div className="row py-5 px-3 text-justify">
              <p>
                The coastal family run business based in the seaside town, North Berwick launched
                the brand in 2022 to cater to the rebels of the East coast.{" "}
              </p>
              <p>
                North Berwick is a picturesque seaside town in East Lothian, boasting incredible
                beaches, coastal scenery and home of North Berwick Law. Possessing an eclectic mix
                of shops, epic coffee spots and it's very own skatepark.{" "}
              </p>
              <p>
                East Lothian is a rad place to have an adventure, sea swim, enjoy the bustling surf
                scene, try something new, hone your skills or just take some time for yourself.{" "}
              </p>
              <p>
                Swamped in the adventure lifestyle, drawing inspiration from our surroundings whilst
                cosied round a beach fire, Fun for rebels was founded. Our family is stoked to bring
                you a brand that feels unique, diverse & fun whilst rebelling from the ordinary. Our
                collections offer products for adults and children with comfort and affordability
                very much in mind.{" "}
              </p>
              <p>
                With the advent of internet shopping we recognise the benefits of shopping online
                we're thrilled to open our online store.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
