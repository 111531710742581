import React from "react";
import { reducer, initialState } from "./reducer";

export const ShopifyContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const ShopifyProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <ShopifyContext.Provider value={[state, dispatch]}>{children}</ShopifyContext.Provider>;
};
